import React from 'react'

const MasterAdmin = () => {
  return (
    <div> 
        <h1 className='Headtext'> Master Panel</h1>
        <a href='/AdminPanel'>Post Panel</a><br/>
        <a href='/BlogPanel'>Blog Panel</a>
        
         </div>
  )
}

export default MasterAdmin